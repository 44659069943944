<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-4 gap-4">
          <asom-form-field
            class="col-span-2"
            :label="'Inventory Group'"
            :state="inputStates('formData.inventoryGroup')"
            error="Inventory Group is required"
            required
          >
            <asom-input-select
              disabled
              :state="inputStates('formData.inventoryGroup')"
              v-model="formData.inventoryGroup"
              :options="inventoryGroups"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            :label="'Inventory Type Name'"
            :state="inputStates('formData.inventoryTypeName')"
            error="Inventory Type is required"
            required
          >
            <asom-input-text
              v-model="formData.inventoryTypeName"
              :state="inputStates('formData.inventoryTypeName')"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            label="Description"
            :state="inputStates('formData.description')"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.description"
              :state="inputStates('formData.description')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            required
            label="Remarks"
            :state="inputStates('formData.remarks')"
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <asom-form-field
            label="Require Expire Date"
            required
            :state="inputStates('formData.requiredExpiredDate')"
          >
            <asom-input-checkbox
              v-model="formData.requiredExpiredDate"
              label="Expire Date Required"
              description="Check if Expire Date is Required"
            />
          </asom-form-field>
          <asom-form-field
            :label="'Require Secondary Location'"
            :state="inputStates('formData.requiredSecondaryLocation')"
            required
          >
            <asom-input-checkbox
              v-model="formData.requiredSecondaryLocation"
              label="Secondary Location Required"
              description="Check if Secondary Location is Required"
            />
          </asom-form-field>
          <div class="col-span-4 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Update"
              @click="updateinventoryTypeClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import { useVuelidate } from "@vuelidate/core";
import {
  editInventoryType,
  getInventoryTypeDetails,
} from "../../../../services/inventory.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: false,
      formData: {
        inventoryGroup: null,
        inventoryTypeName: null,
        description: null,
        remarks: null,
        requiredExpiredDate: false,
        requiredSecondaryLocation: false,
      },
    };
  },
  validations() {
    return {
      formData: {
        inventoryGroup: { required },
        inventoryTypeName: { required },
        remarks: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
    }),
    inventoryGroups() {
      return [
        {
          label: InventoryGroups.GENERAL.NAME,
          value: InventoryGroups.GENERAL.VALUE,
        },
        {
          label: InventoryGroups.SMART_CARD.NAME,
          value: InventoryGroups.SMART_CARD.VALUE,
        },
        {
          label: InventoryGroups.SERIALISED_TAG.NAME,
          value: InventoryGroups.SERIALISED_TAG.VALUE,
        },
        {
          label: InventoryGroups.FIRST_AID.NAME,
          value: InventoryGroups.FIRST_AID.VALUE,
        },
      ];
    },
  },
  mounted() {
    this.getTypeDetails();
  },
  methods: {
    async getTypeDetails() {
      const result = await getInventoryTypeDetails({
        inventoryTypeId: get(this.$route.params, "inventoryTypeId"),
        stationId: this.stationId,
      });
      if (result.success) {
        this.setFormData(get(result.payload, "inventoryTypeDetailsViewModel"));
      }
    },
    setFormData(data) {
      this.formData.inventoryGroup = {
        label: get(data, "inventoryGroup"),
        value: get(data, "inventoryGroupEnum"),
      };
      this.formData.inventoryTypeName = get(data, "inventoryTypeName");
      this.formData.remarks = get(data, "remarks");
      this.formData.requiredSecondaryLocation = get(
        data,
        "requiredSecondaryLocation"
      );
      this.formData.requiredExpiredDate = get(data, "requiredExpiredDate");
      this.formData.description = get(data, "description");
    },
    updateinventoryTypeClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await editInventoryType({
        inventoryTypeId: get(this.$route.params, "inventoryTypeId"),
        inventoryTypeName: this.formData.inventoryTypeName,
        remarks: this.formData.remarks,
        requiredPrimaryLocation: true,
        requiredSecondaryLocation: this.formData.requiredSecondaryLocation,
        requiredExpiredDate: this.formData.requiredExpiredDate,
        description: this.formData.description,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
